const host = process.env.REACT_APP_API_BASE_URL;
const target = process.env.REACT_APP_BUILD_TARGET;

const routes = {
  auth: `${host}/${target || 'user'}/account`,
  languages: `${host}/api/language/search`,
  currencies: `${host}/api/currency/search`,

  config: `${host}/manage/config`,
  configEdit: `${host}/manage/config/edit`,
  configs: `${host}/manage/config/search`,

  login: `${host}/${target || 'user'}/login`,

  attachment: `${host}/manage/attachment`,
  attachments: `${host}/manage/attachment/search`,

  offer: `${host}/manage/offer`,
  offerCreate: `${host}/manage/offer/create`,
  offerEdit: `${host}/manage/offer/edit`,
  offers: `${host}/manage/offer/search`,

  offerAmenity: `${host}/manage/offer/amenity`,
  offerAmenityCreate: `${host}/manage/offer/amenity/create`,
  offerAmenityEdit: `${host}/manage/offer/amenity/edit`,
  offerAmenities: `${host}/manage/offer/amenity/search`,

  offerModule: `${host}/manage/offer/module`,
  offerModuleCreate: `${host}/manage/offer/module/create`,
  offerModuleEdit: `${host}/manage/offer/module/edit`,
  offerModules: `${host}/manage/offer/module/search`,

  offerModuleType: `${host}/manage/offer/module/type`,
  offerModuleTypeCreate: `${host}/manage/offer/module/type/create`,
  offerModuleTypeEdit: `${host}/manage/offer/module/type/edit`,
  offerModuleTypes: `${host}/manage/offer/module/type/search`,

  offerPlanning: `${host}/manage/offer/planning`,
  offerPlanningCreate: `${host}/manage/offer/planning/create`,
  offerPlanningEdit: `${host}/manage/offer/planning/edit`,
  offerPlannings: `${host}/manage/offer/planning/search`,

  offerType: `${host}/manage/offer/type`,
  offerTypeCreate: `${host}/manage/offer/type/create`,
  offerTypeEdit: `${host}/manage/offer/type/edit`,
  offerTypes: `${host}/manage/offer/type/search`,

  translation: `${host}/api/translation`,
  translationEdit: `${host}/manage/translation/edit`,
  translations: `${host}/manage/translation/search`,

  seo: `${host}/manage/seo/search`,
  seoCreate: `${host}/manage/seo/create`,
  seoEdit: `${host}/manage/seo/edit`,
  seoOne: `${host}/manage/seo`,
};

export default routes;
